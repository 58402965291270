@font-face {
  font-family: "ABITE";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/ABITE.ttf");
}

@font-face {
  font-family: "AbrilFatface";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/AbrilFatface-Regular.ttf");
}

@font-face {
  font-family: "AlternateGothic";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Alternate+Gothic+No1+D+Regular.otf");
}

@font-face {
  font-family: "Anton";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Anton-Regular.ttf");
}

@font-face {
  font-family: "Antonio-Variable";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Antonio-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Arizonia";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Arizonia-Regular.ttf");
}

@font-face {
  font-family: "Astagina";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Astagina+Signature.otf");
}

@font-face {
  font-family: "Bebas";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Bebas-Regular.otf");
}

@font-face {
  font-family: "BebasNeue";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/BebasNeue-Regular.ttf");
}

@font-face {
  font-family: "Chunk";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Chunk.otf");
}

@font-face {
  font-family: "CrimsonPro";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/CrimsonPro-VariableFont_wght.ttf");
}

@font-face {
  font-family: "FUTRFW";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/FUTRFW.TTF");
}

@font-face {
  font-family: "Inter";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Inter-VariableFont_slnt%2Cwght.ttf");
}

@font-face {
  font-family: "KeplerStd";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/KeplerStd-Regular.otf");
}

@font-face {
  font-family: "Montserrat";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
  font-family: "ObjektivMk1";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/ObjektivMk1_Trial_XBd.ttf");
}

@font-face {
  font-family: "PaytoneOne";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/PaytoneOne-Regular.ttf");
}

@font-face {
  font-family: "PlayfairDisplaySC-Bold";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/PlayfairDisplaySC-Bold.ttf");
}

@font-face {
  font-family: "PlayfairDisplaySC";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/PlayfairDisplaySC-Regular.ttf");
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/PlusJakartaSans-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Quicksand";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Quicksand-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Reglisse_Back";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Reglisse_Back.otf");
}

@font-face {
  font-family: "SourceSansPro";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "Turret";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/TurretRoad-ExtraBold.ttf");
}

@font-face {
  font-family: "TypoDraftDemo";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Typo+Draft+Demo.otf");
}

@font-face {
  font-family: "Urbanist";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Urbanist-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Cybrpnuk2";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Cybrpnuk2.ttf");
}

@font-face {
  font-family: "LeagueSpartan";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/LeagueSpartan-VariableFont_wght.ttf");
}

@font-face {
  font-family: "NordBold";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/NordBold.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
  font-family: "SairaCondensed";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/SairaCondensed-Bold.ttf");
}

@font-face {
  font-family: "norwester";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/norwester.otf");
}

@font-face {
  font-family: "Lovelo";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Lovelo+Black.otf");
}

@font-face {
  font-family: "GaretBook";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Garet-Book.woff");
}

@font-face {
  font-family: "GaretHeavy";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Garet-Heavy.ttf");
}

@font-face {
  font-family: "norwester";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/norwester.otf");
}

@font-face {
  font-family: "MontserratExtraBold";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/FontsFree-Net-Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: "zuumerough";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/FontsFree-Net-zuumerough-bold-2.ttf");
}

@font-face {
  font-family: "Austin";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Austin+Capittal.ttf");
}

@font-face {
  font-family: "JosefinSans";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/JosefinSans-VariableFont_wght.ttf");
}

@font-face {
  font-family: "JosefinSans-Italic";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/JosefinSans-Italic-VariableFont_wght.ttf");
}

@font-face {
  font-family: "SairaCondensed";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/SairaCondensed-Bold.ttf");
}

@font-face {
  font-family: "PlayfairDisplay-Italic";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/PlayfairDisplay-Italic-VariableFont_wght.ttf");
}

@font-face {
  font-family: "RussoOne";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/RussoOne-Regular.ttf");
}

@font-face {
  font-family: "AbrilFatface";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/AbrilFatface-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "ZCOOLXiaoWei";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/ZCOOLXiaoWei-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: "Amaranth-Bold";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Amaranth-Bold.ttf");
}

@font-face {
  font-family: "Amaranth-Regular.ttf";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Amaranth-Regular.ttf");
}

@font-face {
  font-family: "impact";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/impact.ttf");
}

@font-face {
  font-family: "Archivo";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/ArchivoBlack-Regular.woff");
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/RobotoCondensed-Regular.ttf");
}

@font-face {
  font-family: "repoblack";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/repo-black-webfont.woff");
}

@font-face {
  font-family: "Lato";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: "LibreBaskerville";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/LibreBaskerville-Regular.ttf");
}

@font-face {
  font-family: "LeagueSpartan";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/LeagueSpartan-VariableFont_wght.ttf");
}
@font-face {
  font-family: "bebas-neue-cyrillic-regular";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/bebas-neue-cyrillic-regular.ttf");
}

@font-face {
  font-family: "repo-black";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/repo-black-webfont.woff");
}
@font-face {
  font-family: "ArchivoBlack";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/ArchivoBlack-Regular.ttf");
}
@font-face {
  font-family: "ClearSans";
  src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/ClearSans-Regular.ttf");
}
@font-face {
    font-family: "norwester";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/norwester.otf");
}
@font-face {
    font-family: "SairaCondensed";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/SairaCondensed-Bold.ttf");
}
@font-face {
    font-family: "Barlow";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Barlow-Bold.ttf");
}
