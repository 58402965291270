.canvas_tools.col-lg-7 {
    padding: 0 75px 0 0 !important;
}

@media screen and (max-width:1720.98px) {
    .canvas_edit.col-lg-5 {
        width: 43%;
    }

    .canvas_tools.col-lg-7 {
        width: 57%;
    }

}

@media screen and (max-width:1650.98px) {
    .canvas_edit.col-lg-5 {
        width: 46%;
    }


    .canvas_tools.col-lg-7 {
        width: 54%;
    }

}


@media screen and (max-width:1566.98px) {
    .canvas_edit.col-lg-5 {
        width: 48%;
    }


    .canvas_tools.col-lg-7 {
        width: 52%;
    }


}


@media screen and (max-width:1508.98px) {
    .canvas_edit.col-lg-5 {
        width: 50%;
    }


    .canvas_tools.col-lg-7 {
        width: 50%;
    }


}

@media screen and (max-width:1448.98px) {
    .canvas_edit.col-lg-5 {
        width: 52%;
    }


    .canvas_tools.col-lg-7 {
        width: 48%;
    }

    .profile_canvas_box {

        height: 527px;
    }

    .profile_canvas_tools {
        height: 527px;

    }

}

@media screen and (max-width:1396.98px) {
    .canvas_edit.col-lg-5 {
        width: 54%;
    }


    .canvas_tools.col-lg-7 {
        width: 46%;
    }

    .Ai-img-part {
        width: 100%;
        height: 100%;
    }

    .Ai-img-part img {
        width: 100%;
        height: 100%;
    }

}

@media screen and (max-width:1358.98px) {


    .profile_canvas_box {

        height: 514px;
    }

    .profile_canvas_tools {
        height: 514px;

    }


}

@media screen and (max-width:1350.98px) {
    .canvas_edit.col-lg-5 {
        width: 56%;
    }


    .canvas_tools.col-lg-7 {
        width: 44%;
    }

    .profile_canvas_box {

        height: 514px;
    }

    .profile_canvas_tools {
        height: 514px;

    }


}

@media screen and (max-width:1308.98px) {
    .canvas_edit.col-lg-5 {
        width: 58%;
    }


    .canvas_tools.col-lg-7 {
        width: 42%;
    }

}


@media screen and (max-width:1260.98px) {
    .canvas_edit.col-lg-5 {
        width: 60%;
    }


    .canvas_tools.col-lg-7 {
        width: 40%;
    }

}


@media screen and (max-width:1217.98px) {
    .canvas_edit.col-lg-5 {
        width: 62%;
    }


    .canvas_tools.col-lg-7 {
        width: 38%;
    }
}

@media screen and (max-width:1185.98px) {
    .canvas_edit.col-lg-5 {
        width: 64%;
    }


    .canvas_tools.col-lg-7 {
        width: 36%;
    }
}


@media screen and (max-width:1155.98px) {
    .canvas_edit.col-lg-5 {
        width: 66%;
    }


    .canvas_tools.col-lg-7 {
        width: 34%;
    }
}



@media screen and (max-width:1155.98px) {
    .canvas_edit.col-lg-5 {
        width: 68%;
    }


    .canvas_tools.col-lg-7 {
        width: 32%;
    }
}


@media screen and (max-width:1122.98px) {
    .canvas_edit.col-lg-5 {
        width: 68%;
    }


    .canvas_tools.col-lg-7 {
        width: 32%;
    }
}

@media screen and (max-width:1085.98px) {
    .canvas_edit.col-lg-5 {
        width: 68%;
        overflow-x: auto;
    }


    .canvas_tools.col-lg-7 {
        width: 32%;
    }
}